<template>
  <div id="merchant-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('FC Operations Supervisor')}}</h2>
      </div>
      <div class="vx-col w-full lg:w-1/2 items-end">
        <div class="flex justify-between">
          <span class="font-semibold"></span>
          <span class="font-medium text-primary cursor-pointer">
            <vs-button
              @click="openAddUserModal('add')"
              color="primary"
              icon-pack="feather"
              icon="icon-plus"
              size="large"
            >
            </vs-button>
          </span>
        </div>
      </div>
    </div>

    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      :data="fcSupervisors"
      :tableLoader="tableLoader"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('Email')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].email">
            {{ data[indextr].email }}
          </shipblu-td>

          <shipblu-td>
            <vs-dropdown
              vs-trigger-click
              class="dd-actions cursor-pointer"
              @click="selected = []"
            >
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-more-horizontal"
                class="mr-2"
              >
              </vs-button>
              <vs-dropdown-menu style="width: 190px">

                <!-- ACTIONS FOR ON CREATED -->
                <vs-dropdown-item
                  @click="openAddUserModal('edit', data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Edit')}}</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item
                  @click="resetPassword(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Change Password')}}</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item
                  @click="deleteFcSupervisor(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="TrashIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Delete')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <fc-operations-supervisor :type="type" :data="fcSupervisor" :addUserModal="addUserModal"
    @addUserModal="addUserModal = $event" @loadData="loadData"></fc-operations-supervisor>
    <reset-password userRole="fc-operations-supervisor" :resetPass="resetPass" :userData="userData" @loadData="loadData" @resetPass="resetPass = $event"></reset-password>
  </div>
</template>

<script>
import FcOperationsSupervisor from './components/AddFcOperationsSupervisor.vue'
import ResetPassword from './components/FulfillmentResetPassword.vue'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      fcSupervisors: [],
      deleteData: {},
      addUserModal: false,
      type: '',
      fcSupervisor: {},
      resetPass: false,
      tableLoader: false,
      userData: {}
    }
  },
  methods: {
    loadData () {
      this.tableLoader = true
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/users/fc-operations-supervisor/', 'get', null, true,
        (response) => {
          this.fcSupervisors = response.data
          this.fcSupervisors = this.fcSupervisors.reverse()
          this.tableLoader = false
        }
      )
    },
    openAddUserModal (type, data) {
      this.type = type
      if (type === 'add') {
        this.fcSupervisor = {}
      } else {
        this.fcSupervisor = data
      }
      this.addUserModal = true
    },
    deleteFcSupervisor (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/users/fc-operations-supervisor/${this.deleteData.user_id}`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['FC Operations Supervisor'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadData()
        }
      )
    },
    resetPassword (data) {
      this.resetPass = true
      this.userData = data
    }
  },
  created () {
    this.loadData()
  },
  components: {
    FcOperationsSupervisor,
    ResetPassword,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
